import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Floater from 'components/floating-grid'
import Layout from 'components/layout'
import Content from 'components/category/content'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/implements/utility-tractors/livestock-equine-equipment/chain-unloading-manure-spreaders/',
    imageId: 'chainUnloadingImage',
    name: 'Chain-Unloading Manure Spreaders',
    alt: 'Chain-Unloading Manure Spreaders',
  },
  {
    link: '/implements/utility-tractors/livestock-equine-equipment/grinder-mixers/',
    imageId: 'grinderMixersImage',
    name: 'Grinder Mixers',
    alt: 'Grinder Mixers',
  },
  {
    link: '/implements/utility-tractors/livestock-equine-equipment/large-hydraulic-push-manure-spreaders/',
    imageId: 'largyHydraulicImage',
    name: 'Hydraulic Push Manure Spreaders',
    alt: 'Hydraulic Push Manure Spreaders',
  },
  {
    link: '/implements/utility-tractors/livestock-equine-equipment/side-discharge-manure-spreaders/',
    imageId: 'sideDischargeImage',
    name: 'Side Discharge Manure Spreaders',
    alt: 'Side Discharge Manure Spreaders',
  },
]

const LivestockEquineEquipmentPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>Utility Tractor Implements - Livestock/Equine Equipment | Hutson Inc</title>
        <meta
          name='description'
          content='Find Frontier mixers and manure spreaders for John Deere utility tractors at Hutson. We carry all Frontier and John Deere livestock and equine equipment.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Implements',
                'item': 'https://www.hutsoninc.com/implements/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Utility Tractors',
                'item': 'https://www.hutsoninc.com/implements/utility-tractors/',
              },
              {
                '@type': 'ListItem',
                'position': 4,
                'name': 'Livestock/Equine Equipment',
                'item':
                  'https://www.hutsoninc.com/implements/utility-tractors/livestock-equine-equipment/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Livestock/Equine Equipment' />

      <Content>
        <Floater items={gridItems} />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    heroImage: file(
      relativePath: { eq: "implements/utility-tractors/livestock-equine-equipment-header.jpg" }
    ) {
      ...FullWidthImage
    }
    chainUnloadingImage: file(
      relativePath: {
        eq: "implements/utility-tractors/livestock-equine-equipment/chain-unloading-manure-spreaders.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    grinderMixersImage: file(
      relativePath: {
        eq: "implements/utility-tractors/livestock-equine-equipment/grinder-mixers.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    largyHydraulicImage: file(
      relativePath: {
        eq: "implements/utility-tractors/livestock-equine-equipment/large-hydraulic-push-manure-spreaders.jpg"
      }
    ) {
      ...FloatingGridImage
    }
    sideDischargeImage: file(
      relativePath: {
        eq: "implements/utility-tractors/livestock-equine-equipment/side-discharge-manure-spreaders.jpg"
      }
    ) {
      ...FloatingGridImage
    }
  }
`

export default LivestockEquineEquipmentPage
